.object__center{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%)
}

.ticket-bg{
    border-radius: 5px;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
}

.refund_details{
    border-radius: 8px;
border: 1px solid #E4E7EC;
background: rgba(242, 242, 242, 0.30);
}