.card-shadow{
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    border: 1px solid #E4E7EC;
}

.search_card{
    border-radius: 5px;
    border: 1px solid var(--stroke, #D0D5DD);
    background: #FFF;
    box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.10);
}