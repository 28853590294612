.button {
    font-size: 1rem;
    border-radius: 8px;
    padding: 0.8rem 2.2rem;
    font-family: 'Graphik', 'sans-serif';
    cursor: pointer;
}

.button-shadow{
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
