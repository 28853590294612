.input_button:focus{
    border-color:var(--primary);
    outline: var(--primary);
}

#total{
    padding: 10px;
    margin-left: 5px;
  }

.input-form{
    border:1px solid #D0D5DD;
    border-radius: 6px;
}