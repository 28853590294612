.dashboard-modal {
  z-index: 100;
  position: fixed;
  top: 14vh;
  left: 20%;
  width: 60%;
  background: white;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
  
}

.dashboard-modal-loading{
  width:60% !important;
  margin: 0 auto !important;
}



@media (max-width: 600px) {
  .dashboard-modal-loading{
      width: 80% !important;
  }
  .dashboard-modal{
    left: 10%;
    width: 80% !important;
  }
}


.dashboard-modal-top-right{
  top:0% !important;
  right:0% !important;
}

.dashboard-modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: var(--primary);
  color: white;
}

.dashboard-modal__header h2 {
  margin: 0.5rem;
}

.dashboard-modal__content {
  padding: 1rem 0.5rem;
}

.dashboard-modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .dashboard-modal {
    left: calc(50% - 12rem);
    width: 24rem !important;
  }
}

.dashboard-modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.dashboard-modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.dashboard-modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.dashboard-modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}


.form__input{
  background: #FFFFFF;
  /* Gray/300 */

  border: 1px solid #D0D5DD;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.box-card{
  border-radius: 8px;
  border: 1px solid var(--stroke, #D0D5DD);
  background: rgba(242, 242, 242, 0.30);
}

.cancel-box{
  border-radius: 8px;
border: 1px solid var(--stroke, #D0D5DD);
background: var(--white, #FFF);
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

}